import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsFeed from "../components/news/news-feed"

const desc =
  "Stay up to date with news from and about Archerhub, the premier digital freight marketplace with AI-enabled technology."
const News = () => (
  <Layout>
    <SEO title="News" description={desc} />
    <NewsFeed />
  </Layout>
)

export default News
