import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import archerhubnews from "../../images/archerhubnews.png"
// import Utils from "../../services/utils"
import Button from "../shared/button"
// const getExcerpt = str => {
//   if (!str) return ""
//   str = str.replace(/<[^>]*>?/gm, "")
//   return str.substring(0, 300) + "..."
// }
const NewsFeed = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allWordpressPost (filter: {categories: {elemMatch: {slug: {eq: "news"}}}}){
        edges {
          node {
            title
            excerpt
            slug
            wordpress_id
            featured_media {
              source_url
              title
            }
          }
        }
      }
    }
  `)

  const posts = data.allWordpressPost.edges
  return (
    <div className="container mx-auto p-6 mt-8 lg:mt-20">
      <h1 className="text-4xl text-gray-700 font-bold text-center md:text-left mb-5">
        Archerhub News
      </h1>
      <div className="flex flex-wrap justify-start">
        {posts.map(({ node }) => {
          const title = node.title || node.slug
          return (
            <div className="w-full mb-5" key={node.wordpress_id}>
              <div className="flex flex-wrap md:flex-no-wrap justify-start items-center">
                <div className="w-full md:w-1/4 lg:w-1/4">
                  <Link to={`/news/${node.slug}`}>
                    {node.featured_media ? (
                      <div
                        className="h-48 bg-cover bg-no-repeat rounded"
                        style={{
                          backgroundImage: `url(${node.featured_media.source_url})`,
                        }}
                        title={node.featured_media.title}
                      />
                    ) : (
                      <div
                        className="h-48 bg-cover bg-center rounded"
                        style={{
                          backgroundImage: `url(${archerhubnews})`,
                        }}
                        title="Default Image"
                      />
                    )}
                  </Link>
                </div>
                <div className="md:w-3/4 lg:w-3/4">
                  <aside className="lg:pl-5 pt-5 lg:mt-0">
                    <h3 className="text-gray-700 text-xl lg:text-3xl font-bold hover:text-blue-600">
                      <Link to={`/news/${node.slug}`} dangerouslySetInnerHTML={{__html:title}}></Link>
                    </h3>
                    <p className="text-gray-700 font-normal lg:text-justify my-3 leading-relaxed">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: node.excerpt,
                        }}
                      ></span>
                    </p>
                    <div className="flex justify-between items-center mt-3">
                      <div>
                        <Link to={`/news/${node.slug}`}>
                          <Button
                            className="hidden lg:block bg-transparent hover:bg-blue-500 hover:underline text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded my-2"
                            type="button"
                          >
                            Read More →
                          </Button>
                          <span className="lg:hidden text-blue-500 underline">
                            Read More →
                          </span>
                        </Link>
                      </div>
                      {/* <div className="text-gray-600">
                        {Utils.longDate(node.createdAt)} •{" "}
                        <span className="italic">{node.author}</span>
                      </div> */}
                    </div>
                  </aside>
                </div>
              </div>
              <hr className="my-5" />
            </div>
          )
        })}
      </div>
    </div>
  )
  //}
}
export default NewsFeed
